import React from 'react';

import { RouteComponentProps, Router } from '@reach/router';
import BoxScore from 'src/components/box-score/box-score';
import Layout from 'src/components/layout/layout';
import SEO from 'src/components/seo';
import { usePrivateRoute } from 'src/utils/auth';

const PlayerProfilePage = () => {
  usePrivateRoute();
  return (
    <Router basepath="/players">
      <PlayerProfileRoute path="/:playerId/boxScore/:id" />
    </Router>
  );
};

interface PlayerProfileRouteProps extends RouteComponentProps {
  id?: string;
  playerId?: string;
}

const PlayerProfileRoute: React.FC<PlayerProfileRouteProps> = ({ id, playerId }) => {
  return (
    <Layout>
      <SEO title="Box Score" />
      <BoxScore id={id} playerId={playerId} />
    </Layout>
  );
};

export default PlayerProfilePage;
